import SaleItem from "./saleItem";
import EDocument from "./edocument";
import store from "../store";

const CreditNote = class {
    constructor(object) {
        if (object === undefined) {
            this.id = null;
            this.saleId = null;
            this.reason = null;
            this.customer = null;
            this.saleSerialNumber = null;
            this.reason = null;
            this.base0 = 0.0;
            this.base12 = 0.0;
            this.tax = 0.0;
            this.taxPercentage = store.getters.business.taxPercentage;
            this.createdAt = null;
            this.items = [];
            this.edoc = null;
        } else {
            this.id = object.id;
            this.saleId = object.saleId;
            this.reason = object.reason;
            this.customer = object.customer;
            this.saleSerialNumber = object.saleSerialNumber;
            this.reason = object.reason;
            this.base0 = parseFloat(object.base0);
            this.base12 = parseFloat(object.base12);
            this.tax = parseFloat(object.tax);
            this.taxPercentage = object.taxPercentage || store.getters.business.taxPercentage;
            this.createdAt = new Date(Date.parse(object.createdAt));
            
            // Parse items
            if (object.items !== undefined && object.items !== null && object.items.length > 0) { 
                this.items = object.items.map(item => new SaleItem(item)); 
            }

            // Parse edoc if exists
            if (object.edoc !== undefined && object.edoc !== null) {
                this.edoc = new EDocument(object.edoc);
            } else { this.edoc = null }
        }
    }

    get total() {
        return this.base0 + this.base12 + this.tax;
    }

    get createdAtFormatted() {
        const options = { year: 'numeric', month: 'numeric', day: 'numeric'};
        return this.createdAt.toLocaleDateString('es-EC', options) + " - " + this.createdAt.toLocaleTimeString('en-US');
    }

   get edocState() {
        if (this.edoc === null) { return "PENDIENTE" }
        if (this.edoc.nulled === true) { return "ANULADA" }
        else if (this.edoc.authorized === true) { return "AUTORIZADO" }
        else if (this.edoc.received === true) { return "POR AUTORIZAR" }
        else { return "POR REPORTAR" }
    }
    get edocStateBadgeColor() {
        if (this.edoc === null) { return "gray" }
        if (this.edoc.nulled === true) { return "red" }
        else if (this.edoc.authorized === true) { return "green" }
        else if (this.edoc.received === true) { return "yellow" }
        else { return "orange" }
    }

    updateItems(item, append=true) {
        if (append) { this.items.unshift(item) }
        else {
            let index = this.getItemIndex(item);
            if (index === -1) { this.items.unshift(item) } 
            else { this.items[index] = item }
        }
        this.updateValues();
     }
     deleteItem(item) {
        let index = this.getItemIndex(item);
        this.items.splice(index, 1);
        if (this.items.length == 0) { this.items = [] }
        this.updateValues();
    }
    getItemIndex(item) {
        var index = -1;
        if (item.uid === null || this.items === null || this.items === undefined) { return -1; }
        for (var i=0; i<this.items.length; i++) {
            if (this.items[i].uid === item.uid) { return i; }
        }
        return index;
    }
    updateValues() {
        var sub0_ = 0.0;
        var sub12_ = 0.0;
        var discount_ = 0.0;
        for (var i=0; i<this.items.length; i++) {
            if (this.items[i].hasTax) {
                sub12_ += this.items[i].totalPrice;
            } else {
                sub0_ += this.items[i].totalPrice;
            }
            discount_ += (this.items[i].amount * parseFloat(this.items[i].unitPrice) * this.items[i].discount)
        }
        this.base0 = sub0_;
        this.base12 = sub12_;
        this.tax = sub12_ * store.getters.business.tax;
        this.discount = discount_;
    }
}
export default CreditNote