<template>
	<div class="grid p-fluid">
        <Toast/>
        <div class="col-12">
			<div class="card" :style="{'background-color':this.cardBackgroundColor}">
                <div style="width: 100%; display: flex;">
                    <h2 style="font-size: large;">Nota de Crédito - {{ this.$store.getters.defaultActivity.name }}</h2>
                </div>
                <div class="grid formgrid" style="margin-top: 0px;" v-show="this.sale.id !== null">
                    <div class="col-12">
                        <div class="card">
                            <h5 style="margin-bottom: 25px;"><b>Factura {{ (this.sale.id !== null) ? " N: " + this.sale.serialNumber : this.creditNote.saleSerialNumber }}</b></h5>
                            <div class="p-fluid formgrid grid">
                                <div class="field col-12 md:col-6">
                                    <div style="display: flex;">
                                        <label style="font-weight: bold; text-align: left;">Cliente:</label>
                                        <label style="margin: 0px 0px 0px auto;">{{ (this.sale.customer.id !== null) ? this.sale.customer.name : "CONSUMIDOR FINAL" }}</label>
                                    </div>
                                    <div style="display: flex;">
                                        <label style="font-weight: bold">Céd/RUC/Pasaporte:</label>
                                        <label style="margin: 0px 0px 0px auto;">{{ (this.sale.customer.id !== null) ? this.sale.customer.identification : "9999999999" }}</label>
                                    </div>
                                    <div style="display: flex;">
                                        <label style="font-weight: bold">Dirección</label>
                                        <label style="margin: 0px 0px 0px auto;">{{ (this.sale.customer.id !== null) ? this.sale.customer.address : "-" }}</label>
                                    </div>
                                    <div style="display: flex;">
                                        <label style="font-weight: bold">Correo</label>
                                        <label style="margin: 0px 0px 0px auto;">{{ (this.sale.customer.email !== null) ? this.sale.customer.email : "-" }}</label>
                                    </div>
                                    <div style="display: flex;">
                                        <label style="font-weight: bold">Teléfono</label>
                                        <label style="margin: 0px 0px 0px auto;">{{ (this.sale.customer.id !== null) ? this.sale.customer.phone : "9999999999" }}</label>
                                    </div>
                                    <div style="display: flex;">
                                        <label style="font-weight: bold">Fecha Emisión</label>
                                        <label style="margin: 0px 0px 0px auto;">{{ (this.sale.id !== null) ? this.sale.createdAt.toLocaleDateString("es-ES") : "" }}</label>
                                    </div>
                                </div>

                                <div class="field col-12 md:col-6 saleInformationContainer">
                                    <div style="display: flex;">
                                        <label style="font-weight: bold">Subtotal 0%</label>
                                        <label style="margin: 0px 0px 0px auto;">{{ this.sale.sub0.toFixed(2) }}</label>
                                    </div>
                                    <div style="display: flex;">
                                        <label style="font-weight: bold">Subtotal {{ this.sale.taxPercentage }}%</label>
                                        <label style="margin: 0px 0px 0px auto;">{{ this.sale.sub12.toFixed(2) }}</label>
                                    </div>
                                    <div style="display: flex;">
                                        <label style="font-weight: bold">Subtotal sin impuestos</label>
                                        <label style="margin: 0px 0px 0px auto;">{{ (this.sale.sub0 + this.sale.sub12).toFixed(2) }}</label>
                                    </div>
                                    <div style="display: flex;">
                                        <label style="font-weight: bold">IVA ({{ this.sale.taxPercentage }}%)</label>
                                        <label style="margin: 0px 0px 0px auto;">{{ (this.sale.tax).toFixed(2) }}</label>
                                    </div>
                                    <div style="display: flex;">
                                        <label style="font-weight: bold">Descuento</label>
                                        <label style="margin: 0px 0px 0px auto;">{{ this.sale.discount.toFixed(2) }}</label>
                                    </div>
                                    <div style="display: flex;">
                                        <label style="font-weight: bold">Total</label>
                                        <label style="margin: 0px 0px 0px auto;">{{ this.sale.total.toFixed(2) }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="grid formgrid" style="margin-top: 20px;">
                    <div class="col-12">
                        <div class="card">
                            <h5><b>Producto(s) / Servicio(s) a retornar</b></h5>
                            <Button icon="pi pi-plus" v-if="this.creditNote.id === null" class="p-button-rounded p-button-info mr-2 mb-2" style="position: absolute; top: 15px; right: 20px;" @click="displayProductDialog"/>
                            <DataTable :value="this.creditNote.items" editMode="cell" :scrollable="true" scrollHeight="400px" scrollDirection="both" class="mt-3 editable-cells-table">
                                <Column field="amount" header="Cantidad" :style="{width: '12.5%','min-width':'30px'}">
                                    <template #editor="{data, field}" v-if="this.creditNote.id === null">
                                        <InputText v-model="data[field]" type="number" autofocus :disabled="this.creditNote.id !== null" @change="onItemChanged(data)" />
                                    </template>
                                </Column>
                                <Column field="product" header="Descr." :style="{width: '45%','min-width':'100px'}"></Column>
                                <Column field="hasTax" header="IVA" :sortable="true" :style="{width: '12.5%','min-width':'30px'}">
                                    <template #body="slotProps">
                                        <span class="p-column-title">IVA</span>
                                        {{slotProps.data.hasTax ? "SI" : "NO"}}
                                    </template>
                                </Column>
                                <Column field="unitPrice" header="Precio Unit." :style="{width: '12.5%','min-width':'40px'}">
                                    <template #body="slotProps">
                                        {{(parseFloat(slotProps.data.unitPrice)).toFixed(2)}}
                                    </template>
                                </Column>
                                <Column field="totalPrice" header="Precio Final" :style="{width: '12.5%','min-width':'40px'}">
                                    <template #body="slotProps">
                                        {{(slotProps.data.totalPrice).toFixed(2)}}
                                    </template>
                                </Column>
                                <Column :exportable="false" :style="{width: '5%','min-width':'60px'}">
                                    <template #body="item" v-if="this.creditNote.id === null">
                                        <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text mr-2 mb-2"  @click="onItemDeleted(item)"/>
                                    </template>
                                </Column>
                            </DataTable>
                            <h5><b>Motivo de retorno</b></h5>
                            <InputText v-model="this.creditNote.reason" id="reason" type="text" :disabled="this.creditNote.id !== null" placeholder="Escriba el motivo de devolución del producto / servicio"/>
                        </div>
                    </div>
                </div>

                <div class="grid formgrid" style="margin-top: 20px;">
                    <div class="col-12">
                        <div class="card">
                            <h5><b>Valores de Nota de Crédito</b></h5>
                            <div class="p-fluid formgrid grid">
                                <div class="field col-12 md:col-6">
                                    <div style="margin-top: 10px;">
                                        <div class="col-12 mb-2 lg:col-12 lg:mb-0">
                                            <label style="margin: 0px;"><b>Secuencial</b></label><br>
                                            <label style="margin: 0px; font-weight: 300;">{{ (this.creditNote.edoc !== null) ? this.creditNote.edoc.sequence : "-" }}</label>
                                        </div>
                                        <div class="col-12 mb-2 lg:col-12 lg:mb-0">
                                            <label style="margin: 0px;"><b>Fecha Emisión</b></label><br>
                                            <label style="margin: 0px; font-weight: 300;">{{ (this.creditNote.edoc !== null && this.creditNote.edoc.sentAt !== null) ? this.creditNote.edoc.sentAt.toLocaleDateString("es-ES") : "-" }}</label>
                                        </div>
                                        <div class="col-12 mb-2 lg:col-12 lg:mb-0">
                                            <label style="margin: 0px;"><b>Fecha Autorización</b></label><br>
                                            <label style="margin: 0px; font-weight: 300;">{{ (this.creditNote.edoc !== null && this.creditNote.edoc.authorizedAt !== null) ? this.creditNote.edoc.authorizedAt.toLocaleDateString("es-ES") : "-" }}</label>
                                        </div>
                                        <div class="col-12 mb-2 lg:col-12 lg:mb-0">
                                            <label style="margin: 0px;"><b>Código de acceso</b></label><br>
                                            <label style="margin: 0px; font-weight: 300; max-width: 90%;">{{ (this.creditNote.edoc !== null) ? this.creditNote.edoc.accessCode : "-" }}</label>
                                        </div>
                                        <div class="col-12 mb-2 lg:col-12 lg:mb-0" v-if="this.creditNote.edoc !== null && this.creditNote.edoc.nulled === true && this.creditNote.edoc.received === true">
                                            <h5 style="margin: 20px 0px 0px 0px;"><b>Esta nota de crédito debe ser anulada en el portal del SRI</b></h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="field col-12 md:col-6 saleInformationContainer">
                                    <div style="display: flex;">
                                        <label>Subtotal 0%</label>
                                        <label style="margin: 0px 0px 0px auto;">{{ this.creditNote.base0.toFixed(2) }}</label>
                                    </div>
                                    <div style="display: flex;">
                                        <label>Subtotal {{ this.creditNote.taxPercentage }}%</label>
                                        <label style="margin: 0px 0px 0px auto;">{{ this.creditNote.base12.toFixed(2) }}</label>
                                    </div>
                                    <div style="display: flex;">
                                        <label>Subtotal sin impuestos</label>
                                        <label style="margin: 0px 0px 0px auto;">{{ (this.creditNote.base0 + this.creditNote.base12).toFixed(2) }}</label>
                                    </div>
                                    <div style="display: flex;">
                                        <label>IVA ({{ this.creditNote.taxPercentage }}%)</label>
                                        <label style="margin: 0px 0px 0px auto;">{{ (this.creditNote.tax).toFixed(2) }}</label>
                                    </div>
                                    <div style="display: flex;">
                                        <label>Total</label>
                                        <label style="margin: 0px 0px 0px auto;">{{ (this.creditNote.base0 + this.creditNote.base12 + this.creditNote.tax).toFixed(2) }}</label>
                                    </div>
                                </div>

                                <div class="field col-12 md:col-12 saleFormButtonContainer" style="margin-top: 35px;" v-show="this.creditNote.edoc === null || this.creditNote.edoc.nulled === false">
                                    <Button label="Limpiar" v-show="showClearButton" class="p-button-raised p-button-danger mr-2 mb-2" style="max-width: 170px" @click="onClearFields"/>
                                    <Button label="Anular" v-show="showDeleteButton" class="p-button-raised p-button-danger mr-2 mb-2" style="max-width: 170px" @click="displayDeleteConfirmation(true)"/>
                                    <div style="margin-left: auto;"></div>
                                    <Button label="Guardar" v-show="showSaveButton" :loading="loadingSaveButton" class="p-button-raised p-button-success mr-2 mb-2" style="max-width: 170px" @click="saveCreditNote()"/>
                                    <Button label="Generar" v-show="showCreateEDOCButton" :loading="loadingCreateEDOCButton" class="p-button-raised p-button-success mr-2 mb-2" style="max-width: 220px;" @click="attemptToCreateCreditNote()"/>
                                    <Menu ref="menu" :model="downloadOptions" :popup="true" style="width: auto; max-width: 170px;">
                                        <template #item="{item}">
                                            <Button type="button" :label="item.label" :icon="item.icon" class="menuButton mr-2 mb-2" :loading="loadingDownloadButton" @click="downloadCreditNote(item.name)"/>
                                        </template>
                                    </Menu>
                                    <Button type="button" label="Descargar" icon="pi pi-angle-down" v-show="showDownloadButton" class="p-button-raised p-button-success mr-2 mb-2" @click="toggleDownloadMenu" style="width: auto; width: 170px;"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Dialog header="Advertencia" v-model:visible="showSubscriptionDialog" :breakpoints="{'960px': '75vw'}" :style="{width: '30vw'}" :modal="true">
                    <p class="line-height-3 m-0">
                        {{ this.error.title }}<br>
                        {{ this.error.mensaje }}<br>
                        {{ this.error.informacionAdicional }}
                    </p>
                    <template #footer>
                        <Button label="Cerrar" @click="closeSubscriptionDialog" class="p-button-secondary mr-2 mb-2"/>
                        <Button label="Ver suscripción" @click="redirectToSubscription" class="p-button-info mr-2 mb-2"/>
                    </template>
                </Dialog>

                <Dialog header="Confirmación" v-model:visible="showDeleteConfirmation" :style="{width: '350px'}" :modal="true">
                    <div class="flex align-items-center justify-content-center">
                        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                        <span>¿Está seguro de anular esta nota de crédito?</span>
                    </div>
                    <template #footer>
                        <Button label="No" @click="displayDeleteConfirmation(false)" class="p-button-secondary mr-2 mb-2"/>
                        <Button label="Sí" :loading="loadingDeleteButton" @click="deleteCreditNote()" class="p-button-warning mr-2 mb-2" autofocus />
                    </template>
                </Dialog>

                <Dialog v-model:visible="showProductDialog" :style="{'max-width': '550px', width: '90%'}" header="Seleccione Producto / Servicio" :modal="true" class="p-fluid">
                    <div class="grid formgrid">
                        <DataTable :value="this.sale.items" selectionMode="single" editMode="cell" :scrollable="true" scrollHeight="500px" scrollDirection="both" class="mt-2 editable-cells-table" :style="{width: '100%'}" @rowSelect="onAddProduct">
                            <Column field="amount" header="Cantidad" :style="{width: '16.66%','min-width':'100px'}"></Column>
                            <Column field="product" header="Descr." :style="{width: '50%','min-width':'100px'}"></Column>
                            <Column field="unitPrice" header="Precio Unit." :style="{width: '16.66%','min-width':'40px'}">
                                <template #body="slotProps">
                                    {{(parseFloat(slotProps.data.unitPrice)).toFixed(2)}}
                                </template>
                            </Column>
                            <Column field="totalPrice" header="Precio Final" :style="{width: '16.66%','min-width':'40px'}">
                                <template #body="slotProps">
                                    {{(slotProps.data.totalPrice).toFixed(2)}}
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                    <template #footer>
                        <Button label="Cancelar" class="p-button-secondary mr-2 mb-2" @click="this.showProductDialog=false"/>
                    </template>
                </Dialog>
			</div>
		</div>
    </div>
</template>

<script>

import download from 'downloadjs'
import httpRequest from '../../service/httpRequest';
import Sale from '../../models/sale';
import CreditNote from '../../models/creditNote';
import SaleItem from '../../models/saleItem';

export default {
	data() {
		return {
            creditNote: new CreditNote(),
            sale: new Sale(),

            // Show
            // showNewButton: false,
            showProductDialog: false,
            showClearButton: false,
            showSaveButton: false,
            showCreateEDOCButton: false,
            showDeleteButton: false,
            showDeleteConfirmation: false,
            showDownloadButton: false,
            showSubscriptionDialog: false,

            // Errors
            cardBackgroundColor: 'white',
            emptyItemListError: false,
            error: {},

            // Loading
            loadingSaveButton: false,
            loadingDeleteButton: false,
            loadingDownloadButton: false,
            loadingCreateEDOCButton: false,

            // Download
            downloadOptions: [
                {
                    label: 'PDF',
                    name: 'pdf',
                    icon: 'pi pi-file-pdf'
                },
                {
                    label: 'XML',
                    name: 'xml',
                    icon: 'pi pi-file-excel'
                },
            ],
		}
	},
    async created() {
        window.scrollTo(0, 0);

        // Get credit note
        let id = this.$route.query.id;
        if (id !== null && id !== undefined) {
            let response = await httpRequest.getCreditNote(id);
            if (response.status == 200) {
                this.sale = new Sale(response.data.sale);
                this.creditNote = new CreditNote(response.data);
                this.setupUI();
            }
        } else {
            // Get sale
            let saleId = this.$route.query.saleId;
            if (saleId !== null && saleId !== undefined) {
                let response = await httpRequest.getSale(saleId);
                if (response.status == 200) {
                    this.sale = new Sale(response.data);
                    this.creditNote = new CreditNote();
                    for (var i=0;i<this.sale.items.length;i++) { this.creditNote.updateItems(this.sale.items[i]) }
                    this.setupUI();
                }
            } else { this.setupUI(); }
        }
    },
	methods: {
        setupUI() {
            this.showClearButton = this.creditNote.id === null;
            this.showDeleteButton = this.creditNote.edoc === null || (this.creditNote.id !== null  && this.creditNote.edoc.nulled === false);
            this.showSaveButton = this.creditNote.id === null;
            this.showCreateEDOCButton = this.creditNote.id !== null && this.creditNote.edoc === null;
            this.showDownloadButton = this.creditNote.edoc !== null  && !this.creditNote.edoc.nulled === true;
            this.cardBackgroundColor = (this.creditNote.edoc !== null && this.creditNote.edoc.nulled === true) ? "#ffc9c9" : "white";
        },
        parseItems(data) {
            this.creditNote.items = data.map(item => new SaleItem(item));
        },
        // Customer
        checkForm() {
            var error = null;
            var creditNoteTotal = parseFloat(this.creditNote.total).toFixed(2);
            var saleTotal = parseFloat(this.sale.total).toFixed(2);
			if (this.creditNote.items.length == 0) { error = "La nota de crédito debe contener uno o más productos / servicios." }
            else if (this.creditNote.reason == null || this.creditNote.reason === "") { error = "Por favor ingrese el motivo de la devolución del producto / servicio." }
            else if (parseFloat(creditNoteTotal) > parseFloat(saleTotal)) { error = "El total de la nota de crédito no puede exceder a $" + this.sale.total.toFixed(2) }
			return error;
		},
        onTotalChanged(event) {
            this.creditNote.base0 = parseFloat(event.target.value).toFixed(2)
            this.creditNote.base12 = parseFloat(event.target.value).toFixed(2)
        },  

        // Product
        displayProductDialog() {
            this.showProductDialog = true;
        },
        onAddProduct(data) {
            let item = new SaleItem(data.data);
            this.creditNote.updateItems(item, false);
        },
        onItemChanged(data) {
            let item = new SaleItem(data);
            this.creditNote.updateItems(item, false);
        },
        onItemDeleted(item) {
            this.creditNote.deleteItem(item.data);
        },

        // Credit Note
        toggleDownloadMenu(event) {
            this.$refs.menu.toggle(event);
        },
        onClearFields() {
            this.creditNote = new CreditNote();
        },
        async saveCreditNote() {
            let error = this.checkForm();
            if (error !== null) {
                this.$toast.add({severity:'error', summary: 'Alerta', detail: error, life: 5000});
            } else {
                // Enable loading mode
                this.loadingSaveButton = true;

                // Send request
                this.creditNote.saleId = this.sale.id;
                let response = await httpRequest.saveCreditNote(this.creditNote);

                // Disable loading mode
                this.loadingSaveButton = false;

                if (response.data !== undefined && response.data !== null) {
                    this.creditNote = new CreditNote(response.data);
                }

                if (response.status === 200) {
                    history.pushState(
                        {},
                        null,
                        this.$route.path + '?id=' + this.creditNote.id + '&saleId=' + this.sale.id
                    )
                    this.$toast.add({severity:'success', summary: 'Éxito', detail: 'Su nota de crédito ha sido guardada y reportada al SRI.', life: 3000});
                } else if (response.status === 802) { 
                    this.error.title = (response.error === "NO_DOC") ? "Documentos electrónicos agotados" : "Suscripción no válida";
                    this.error.mensaje = "Su nota de crédito ha sido guardada por el momento. Para reportar su nota de crédito al SRI por favor renueve su suscripción."
                    this.showSubscriptionDialog = true;
                } else {
                    this.$toast.add({severity:'error', summary: 'Error interno', detail: 'Hemos detectado un error, por favor intente nuevamente.', life: 3000});
                }
                
                this.setupUI();
            }
        },
        async attemptToCreateCreditNote() {
            // Enable loading mode
            this.loadingSaveButton = true;

            // Send request
            let response = await httpRequest.attemptToCreateCreditNote(this.creditNote.id);

            // Disable loading mode
            this.loadingCreateEDOCButton = false;

            if (response.data !== undefined && response.data !== null) {
                this.creditNote = new CreditNote(response.data);
            }

            if (response.status === 200) {
                history.pushState(
                    {},
                    null,
                    this.$route.path + '?id=' + this.creditNote.id + '&saleId=' + this.sale.id
                )
                this.$toast.add({severity:'success', summary: 'Éxito', detail: 'Su nota de crédito ha sido guardada y reportada al SRI.', life: 3000});
            } else if (response.status === 802) { 
                this.error.title = (response.error === "NO_DOC") ? "Documentos electrónicos agotados" : "Suscripción no válida";
                this.error.mensaje = "Su nota de crédito ha sido guardada por el momento. Para reportar su nota de crédito al SRI por favor renueve su suscripción."
                this.showSubscriptionDialog = true;
            } else {
                this.$toast.add({severity:'error', summary: 'Error interno', detail: 'Hemos detectado un error, por favor intente nuevamente.', life: 3000});
            }

            this.setupUI();
        },
        async deleteCreditNote() {
            this.loadingDeleteButton = true;
            let response = await httpRequest.deleteCreditNote(this.creditNote.id);
            this.showDeleteConfirmation = this.loadingDeleteButton = false;
            if (response.status === 200) {
                this.creditNote = new CreditNote(response.data);
                this.setupUI();
                this.$toast.add({severity:'success', summary: 'Éxito', detail: 'Su nota de crédito ha sido anulada, por favor asegúrese de anular este documento en el portal del SRI.', life: 3000});
            } else {
                this.$toast.add({severity:'error', summary: 'Error interno', detail: 'Hemos detectado un error, por favor intente nuevamente.', life: 3000});
            }
        },
        async downloadCreditNote(type) {
            this.loadingDownloadButton = true;
            const response = await httpRequest.downloadCreditNote(this.creditNote.id, type);
            download(response, "nota_credito_" + this.creditNote.edoc.serial + "." + type, "application/" + type);
            this.loadingDownloadButton = false;
        },
        displayDeleteConfirmation(show) {
            this.showDeleteConfirmation = show;
        },
        closeSubscriptionDialog() {
            this.showSubscriptionDialog = false;
        },
        redirectToSubscription() {
            this.showSubscriptionDialog = false;
            this.$router.push('/dashboard/general/suscripcion');
        },
    }
}
</script>

<style scoped lang="scss">
    .menuButton {
        width: 100%;
        background-color: white;
        border: 0px;
        color: darkgrey;
    }
    .menuButton:hover {
        background-color: gray;
    }

    @media screen and (max-width: 769px) {
        .number-textfield {
            width: 150px;
            height: 30px;
            font-size: 13px;;
        }
        .guideReceiptContainer {
            width: 100%; 
            display: block;
        }
        .receiptContainer {
            width: 100%;
            margin-right: 0px;
        }
        .receiptSerialContainer {
            width: 100%;
        }
        .saleFormButtonContainer {
            display: block;
        }
        .paymentContainer {
            display: initial;
        }
        .paymentContainer > div > h5 {
            margin: 10px 0 5px 0; 
        }
        .paymentSubcontainer {
            display: block;
        }
        .collectButton {
            margin-top: 10px;
            padding: 0px;
            width: 100%;
        }
    }
    @media screen and (min-width: 769px) {
        .number-textfield {
            margin: 0px 0px 10px auto;
            width: 100px;
            height: 30px;
            font-size: 15px;

        }
        .guideReceiptContainer {
            width: 100%; 
            display: flex;
        }
        .receiptContainer {
            width: 60%;
            margin-right: 20px;
        }
        .receiptSerialContainer {
            width: 40%;
        }
        .saleFormButtonContainer {
            display: flex;
        }
        .saleInformationContainer {
            padding-left: 50px;
        }
        .paymentContainer {
            display: flex;
            // height: 6rem;
        }
        .paymentContainer > div > h5 {
            margin-bottom: 5px; 
        }
        .paymentSubcontainer {
            display: flex;
        }
        .collectButton {
            margin-left: 10px;
        }
    }
</style>